// extracted by mini-css-extract-plugin
export var popUpWrapper = "AssignCoursePopUp-module--popUpWrapper--1mXmJ";
export var popUpContent = "AssignCoursePopUp-module--popUpContent--3teEX";
export var exitPopUp = "AssignCoursePopUp-module--exitPopUp--3MFc2";
export var headerFlex = "AssignCoursePopUp-module--headerFlex--1jL9t";
export var assignCourse = "AssignCoursePopUp-module--assignCourse--1KNF5";
export var searchUserFlex = "AssignCoursePopUp-module--searchUserFlex--3Ijm0";
export var inputFlex = "AssignCoursePopUp-module--inputFlex--KTEfu";
export var searchInputIcon = "AssignCoursePopUp-module--searchInputIcon--2Uppb";
export var searchresult = "AssignCoursePopUp-module--searchresult--2C3AX";
export var checkAssign = "AssignCoursePopUp-module--checkAssign--27RlJ";
export var uploadSectionFlex = "AssignCoursePopUp-module--uploadSectionFlex--3bZE_";
export var checkUpload = "AssignCoursePopUp-module--checkUpload--3TX-r";
export var excelIcon = "AssignCoursePopUp-module--excelIcon--2Q5Xs";
export var uploadContent = "AssignCoursePopUp-module--uploadContent--2K2CQ";
export var uploadButton = "AssignCoursePopUp-module--uploadButton--EnG0n";
export var uploadCsv = "AssignCoursePopUp-module--uploadCsv--17ljo";
export var backdropWrapper = "AssignCoursePopUp-module--backdropWrapper--1oUgm";