import React from 'react';
import PropTypes from 'prop-types';
import * as styles from '../../../../styles/admin/AssignCoursePopUp.module.scss';

function AssignCoursePopUp(props) {
  const { triggerAssignCoursePopUp } = props;
  return (
    <>
      <div className={styles.popUpWrapper}>
        <div className={styles.popUpContent}>
          <div
            role="presentation"
            onClick={() => triggerAssignCoursePopUp(false)}
            className={styles.exitPopUp}
          >
            <span
              className="library-shaw-icon-new"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <i
                className="icon-new-Cross-thin"
                style={{
                  fontSize: '14px',
                  color: '#ffffff',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />
            </span>
          </div>
          <div className={styles.headerFlex}>
            <p className={styles.assignCourse}>Assign course</p>
            <button type="button">Send Invite</button>
          </div>
          <div className={styles.searchUserFlex}>
            <p>Search for users/groups</p>
            <button type="button">CLEAR</button>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.searchInputIcon}>
              <span className="library-shaw-icon-new">
                <i
                  className="icon-new-Search"
                />
              </span>
              <input type="text" placeholder=" Search by name or skill" />
            </div>
          </div>
          <div className={styles.searchresult}>
            <p>
              Rohit Nigam
              <span className="library-shaw-icon-new">
                <i
                  className="icon-new-Cross-thin"
                  style={{
                    fontSize: '10px',
                    color: '#a8abc1',
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                />
              </span>
            </p>
            <p>
              <span>Group: </span>
              Tech Team
              <span className="library-shaw-icon-new">
                <i
                  className="icon-new-Cross-thin"
                  style={{
                    fontSize: '10px',
                    color: '#a8abc1',
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                />
              </span>
            </p>
          </div>
          <p className={styles.checkAssign}>
            Need to assign content to a large number of students?
          </p>
          <div className={styles.uploadSectionFlex}>
            <div className={styles.checkUpload}>
              <p>Upload CSV</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className={styles.excelIcon}>
                  <span className="library-shaw-icon-new">
                    <i
                      className="icon-new-Sheet-2"
                      style={{
                        fontSize: '21px',
                        marginRight: '8px',
                        color: '#21243d',
                      }}
                    />
                  </span>
                </span>
                <p>Download format example file</p>

                <span className="library-shaw-icon-new">
                  <i
                    className="icon-new-Download-21"
                    style={{
                      fontSize: '17px',
                      marginLeft: '16px',
                      cursor: 'pointer',
                    }}
                  />
                </span>
              </div>
            </div>
            <div className={styles.uploadContent}>
              <label
                htmlFor="upload-csv"
                className={styles.uploadButton}
              >
                Upload CSV
                <input
                  type="file"
                  name="photo"
                  id="upload-csv"
                  className={styles.uploadCsv}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.backdropWrapper} />
    </>
  );
}

export default AssignCoursePopUp;

AssignCoursePopUp.propTypes = {
  triggerAssignCoursePopUp: PropTypes.func.isRequired,
};
