import {
  listUsersInGroup,
} from '../../../DataStore/Services';
import { addUsersToGroup, changeGroupName } from '../../../GraphQL/Services';
import { toaster } from '../../../../services/utils';

/**
   * Add user to specific group
   *
   * @param {*} groupId
   * @param {*} usersSelected
   */
const addUserToGroupHandler = async (groupId, usersSelected, enterpriseUsersList) => {
  if (usersSelected?.length) {
    try {
      const promises = usersSelected.map((user) => {
        const selectedUser = enterpriseUsersList?.get(user);

        let isLearner = false;
        let isManager = false;
        if (selectedUser?.roles?.length) {
          isManager = true;
        } else {
          isLearner = true;
        }

        return addUsersToGroup(
          groupId,
          selectedUser?.id,
          selectedUser?.sub,
          isLearner,
          isManager,
        );
      });

      await Promise.all(promises).then((response) => {
        toaster('Users added to the group successfully');
        console.log(response);
      }).catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  }
};

/**
 * update edit group popup data
 * @param {*} groupObject
 * @param {*} selectedRow
 */
const editGroupHandler = async (groupObject, selectedRow, enterpriseUsersList) => {
  try {
    if (groupObject?.groupName !== selectedRow?.groupName) {
      const updateGroupResponse = await changeGroupName(
        selectedRow.id, groupObject.groupName, selectedRow.version,
      );
      if (updateGroupResponse) {
        toaster('Group name updated');
      }
    }

    await addUserToGroupHandler(selectedRow?.id, groupObject?.users, enterpriseUsersList);
  } catch (error) {
    console.log(error);
  }
};

/**
 * format group data
 * @param {*} groupData
 */
const formatGroupData = async (groupData) => {
  let tableData = null;
  const promises = groupData.map((group, index) => {
    const promise = listUsersInGroup(group.id).then((users) => {
      const dateCreated = new Date(group?.createdAt);
      return ({
        key: index,
        groupName: group.name,
        memberCount: users.length,
        dateCreated,
        id: group.id,
      });
    });
    return promise;
  });
  await Promise.all(promises).then((groups) => {
    tableData = groups;
  });
  return tableData;
};

const listAllUsers = (enterpriseUsersList) => {
  const allEnterpriseUsers = [];
  enterpriseUsersList?.forEach((value) => { allEnterpriseUsers.push({ ...value }); });

  const emails = [];
  if (allEnterpriseUsers) {
    for (let i = 0; i < allEnterpriseUsers?.length; i += 1) {
      const user = allEnterpriseUsers[i];
      emails.push({ label: user?.email, value: user?.id });
    }
  }
  return emails;
};

export {
  editGroupHandler,
  formatGroupData,
  addUserToGroupHandler,
  listAllUsers,
};
