/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import { toaster } from '../../../../services/utils';

const listUsersNotInGroup = async (usersInGroup, enterpriseUsersList) => {
  const emailInGroup = usersInGroup?.map((user) => (user?.email));
  const allEnterpriseUsers = [];
  enterpriseUsersList?.forEach((value) => { allEnterpriseUsers.push({ ...value }); });

  const emails = [];
  if (allEnterpriseUsers) {
    for (let i = 0; i < allEnterpriseUsers?.length; i += 1) {
      const user = allEnterpriseUsers[i];
      if (user && !emailInGroup.includes(user?.email)
      && user?.enabled) {
        emails.push({ label: user?.email, value: user?.id });
      }
    }
  }
  return emails;
};
const getUsersInGroup = async (usersInCurrentGroup, enterpriseUsersList) => {
  const userData = [];

  if (usersInCurrentGroup?.length) {
    usersInCurrentGroup.forEach((user) => {
      if (!user.deletedAt) {
        const userDetails = enterpriseUsersList?.get(user?.memberID);
        userDetails && userData.push({
          role: user.isManager ? 'Manager' : 'Learner',
          name: userDetails.name,
          email: userDetails.email,
          memberId: userDetails.id, // This ID is user's own ID
          id: user.id, // This is the ID of the user in the group
          version: user._version,
        });
      }
    });
  } else {
    toaster('No users found', 'error');
  }
  return userData;
};
export {
  listUsersNotInGroup,
  getUsersInGroup,
};
