/* eslint-disable max-lines */
import React from 'react';
import {
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import * as styles from '../../../../styles/admin/BuyMoreLicense.module.scss';
import { ProfileInfo } from '../../../../context/Admin/AdminContext';

type Props = {
  isModalVisible: boolean,
  onCancel: () => void,
  profileInfo: ProfileInfo
}

const BuyMoreLicense: React.FC<Props> = ({
  isModalVisible,
  onCancel,
  profileInfo,
}) => (
  <Modal
    visible={isModalVisible}
    onCancel={() => {
      onCancel();
    }}
    width="85%"
    className="wrapper"
    footer={null}
  >
    <div className={styles.buyMoreLicenseWrapper}>
      <div className={styles.headerSection}>
        <h2 className={styles.title}>Limit exceeded!</h2>
        <div className={styles.noLicenseAvailableContainer}>
          <p className={styles.zero}>
            0
          </p>
          <p className={styles.licensesAvailable}>Licenses available.</p>
        </div>
        <p className={styles.licensesInUse}>All your licenses are currently in use.</p>
        <p className={styles.description}>
          In order to add any more Learners you will need to reach out
          to your account manager and request more licenses.
        </p>
      </div>
    </div>
  </Modal>
);

BuyMoreLicense.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default BuyMoreLicense;
