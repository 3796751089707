// extracted by mini-css-extract-plugin
export var CreateGroupWrapper = "CreateGroup-module--CreateGroupWrapper--3wrc4";
export var addEmail = "CreateGroup-module--addEmail--2PjxW";
export var labelWrapper = "CreateGroup-module--labelWrapper--OSKo1";
export var managerWrapper = "CreateGroup-module--managerWrapper--2xB7q";
export var selectWrapper = "CreateGroup-module--selectWrapper--1Oka5";
export var name = "CreateGroup-module--name--3lcb4";
export var role = "CreateGroup-module--role--11Q7k";
export var dataWrapper = "CreateGroup-module--dataWrapper--2w-7o";
export var member = "CreateGroup-module--member--1MstC";
export var designation = "CreateGroup-module--designation--3mHe3";
export var cancel = "CreateGroup-module--cancel--1PbbV";
export var addmember = "CreateGroup-module--addmember--lhLAd";
export var btnWrapper = "CreateGroup-module--btnWrapper--3NFUt";