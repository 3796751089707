/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import {
  Menu,
  Dropdown,
} from 'antd';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import {
  Role,
} from '../../../models/index';
import AdminContext, { MoreInfoStatus } from '../../../context/Admin/AdminContext';
import AddUser from './Popups/AddUser';
import ImportedUserProgress from './Popups/ImportedUserProgress';
import * as invitesTabStyles from '../../../styles/admin/InviteTab.module.scss';
import * as groupTabStyles from '../../../styles/admin/GroupsTab.module.scss';
import * as GraphQLApiService from '../../GraphQL/Services';
import {
  editGroupHandler,
  addUserToGroupHandler,
} from './Services/GroupsTabService';
import CreateGroup from './Popups/CreateGroup';
import EditGroup from './Popups/EditGroup';
import ConfirmPopup from './Popups/ConfirmPopup';
import AssignCoursePopUp from './Popups/AssignCoursePopUp';
import MoreInfo from '../MoreInfo';
import TableComponent from '../../Shared/Table/TableComponent';
import {
  formatDate, getAppVersion, getHeaders, hasInviteLimits, postEvent, toaster,
} from '../../../services/utils';
import BuyMoreLicense from './Popups/BuyMoreLicense';
import licenseCount from './Services/GetLicenseData';

function GroupsTabView(props) {
  const { identityProviderClient } = props;
  const {
    setShowMoreInfo,
    showMoreInfo,
    userData,
    profileInfo,
    companyInfo,
    enterpriseUsersList,
  } = useContext(AdminContext);
  const [signedInUserDetails, setSignedInUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSendInviteModalVisible, setSendInviteModalVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [showAssignCoursePopUp, setShowAssignCoursePopUp] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [isImportStatusModalVisible, setIsImportStatusModalVisible] = useState(false);
  const [isConfirmPopupLoading, setIsConfirmPopupLoading] = useState(false);
  const [licenseExceeded, setLicenseExceeded] = useState(false);
  const [licenseCountObject, setLicenseCountObject] = useState({});

  const fetchGroupListing = async () => {
    setIsLoading(true);
    try {
      const allGroupDetails = await GraphQLApiService.getGroupsByEnterpriseID(companyInfo?.id);
      const groupData = allGroupDetails.filter((group) => !group?.deletedAt);
      setTableData(groupData);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  /**
   * display modal
   *
   * @param {*} title
   */
  const showModal = (title) => {
    setPopupTitle(title);
    setIsModalVisible(true);
  };

  const onConfirmDelete = async (record) => {
    setIsConfirmPopupLoading(true);

    try {
      const deletedGroup = await GraphQLApiService.deleteGroup(record?.id);
      if (deletedGroup) {
        toaster('Group deleted successfully', 'success', 2);
        setIsConfirmPopupLoading(false);
        setShowDeleteModal(false);
        await fetchGroupListing();
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
      console.log(error);
    }

    setShowDeleteModal(false);
  };

  const deleteGroupHandler = (record) => {
    setShowDeleteModal(true);
    setSelectedRow(record);
  };

  /**
   * get selected row data and show the modal
   *
   * @param {*} record
   */
  const editMembers = (record) => {
    setSelectedRow(record);
    showModal('Edit group');
  };

  // const assignCourseHandler = () => {
  //   navigate('/admin/browse/');
  // };

  const assignPopupHandler = (record) => {
    const invitesLimited = hasInviteLimits(companyInfo?.subscription);
    if (invitesLimited
      && (licenseCountObject?.available <= 0)) {
      setLicenseExceeded(true);
      return;
    }

    setSelectedRow(record);
    setSendInviteModalVisible(true);
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key={1}>
        <button
          id="admin-accounts-groups-edit-members"
          type="button"
          onClick={() => editMembers(record)}
        >
          Edit Members
        </button>
      </Menu.Item>
      {
        (signedInUserDetails?.role === Role.ADMIN
        || signedInUserDetails?.role === Role.OWNER)
        && (
        <Menu.Item key={3}>
          <button
            id="admin-accounts-groups-delete-group"
            type="button"
            onClick={() => deleteGroupHandler(record)}
          >
            Delete Group
          </button>
        </Menu.Item>
        )
      }
    </Menu>
  );
  const columns = [{
    title: 'Group Name',
    dataIndex: 'groupName',
    width: 130,
  }, {
    title: 'Member Count',
    dataIndex: 'memberCount',
    width: 90,
  }, {
    title: 'Date Created',
    dataIndex: 'dateCreated',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated),
    render: (dateCreated) => {
      const displayDate = formatDate(dateCreated);
      return (
        <p className={groupTabStyles.dateCreated}>{displayDate || ''}</p>
      );
    },
    width: 130,
  }, {
    title: 'Actions',
    dataIndex: 'status',
    render: (_, record) => (
      <div>
        <button
          id="admin-accounts-groups-add-new-learners"
          type="submit"
          className={`${groupTabStyles.addProfile} library-shaw-icon-new`}
          onClick={() => assignPopupHandler(record)}
        >
          <i className="icon-new-User-filled-2" />
          <span className={groupTabStyles.plusIcon}>+</span>
        </button>
        <Dropdown overlay={() => menu(record)} trigger={['click']} placement="bottomLeft">
          <a href=" " className={`${groupTabStyles.moreOptions} library-shaw-icon`}>
            <i className="icon-more-3-dots" />
          </a>
        </Dropdown>
      </div>
    ),
    width: 150,
  }];

  const prepareHeaderForCSV = () => {
    const headers = [];
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      if (column.dataIndex !== 'status') {
        headers.push({
          label: column.title,
          key: column.dataIndex,
        });
      }
    }
    return headers;
  };

  /**
   * Create a new group
   *
   * @param {*} groupObject
   */
  const createGroupHandler = async (groupObject) => {
    try {
      const groupData = await GraphQLApiService.createGroup(
        groupObject?.groupName,
        companyInfo?.id,
      );
      if (groupData) {
        toaster('Group created successfully', 'success', 1);
        const groupId = groupData?.id;
        const payload = {
          event: 'Group Created',
          userId: profileInfo?.id,
          enterpriseId: profileInfo?.enterpriseID,
          originalTimestamp: dayjs().toISOString(),
          sentAt: dayjs().toISOString(),
          properties: {
            groupId,
            name: groupObject?.groupName,
            accountName: companyInfo?.name,
          },
        };
        postEvent(payload);
      }

      if (groupObject?.users?.length) {
        const response = await addUserToGroupHandler(
          groupData.id,
          groupObject?.users,
          enterpriseUsersList,
        );
        if (response) {
          toaster('Users added to the group', 'success', 2);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setSelectedRow(null);
    setIsModalVisible(false);
    await fetchGroupListing();
  };

  const passUploadedArrayFromFile = (users) => {
    setFileDataArray([...users]);
  };

  const jsonToCSV = (csvData) => {
    const items = csvData;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row) => header.map((fieldName) => (row[fieldName])).join(',')),
    ].join('\r\n');
    return csv;
  };

  const triggerEvent = async (response) => {
    try {
      const eventApiName = 'enterpriseappevents';
      const eventPath = '/events';
      const headers = await getHeaders();
      const eventPromises = response.map((data) => {
        const payload = {
          event: 'Invite Sent',
          context: {
            app: {
              version: getAppVersion(),
            },
          },
          id: uuidv4(),
          userId: profileInfo?.id,
          enterpriseId: profileInfo?.enterpriseID,
          originalTimestamp: dayjs().toISOString(),
          sentAt: dayjs().toISOString(),
          properties: {
            invitationId: data?.id,
            accountName: companyInfo?.name,
          },
        };
        return API.post(eventApiName, eventPath, {
          headers,
          body: payload,
        });
      });
      await Promise.all(eventPromises);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * send invite
   *
   * @param {*} selectedEmails
   */
  const sendInvite = async (selectedEmails, groupId) => {
    try {
      if (selectedEmails?.length) {
        const apiName = 'enterpriseappinviteuser';
        const path = '/invite';
        const headers = await getHeaders();
        const promises = selectedEmails.map((email) => (
          API.post(apiName, path, {
            headers,
            body: {
              email: email.trim(),
              status: 'REQUESTED',
              inviter_id: profileInfo?.id,
              inviter_enterprise_id: companyInfo?.id,
              group_id: groupId,
            },
          })
        ));
        const responses = await Promise.allSettled(promises);

        const succeededApiCalls = [];
        let inviteAlreadyExists = false;
        responses.forEach((response) => {
          if (response.status === 'fulfilled') {
            succeededApiCalls.push(response.value);
          } else if (response.reason.request.status === 500) {
            inviteAlreadyExists = true;
          }
        });

        if (inviteAlreadyExists) {
          toaster('Invite already exists', 'error');
        } else if (responses.length !== succeededApiCalls.length) {
          toaster('Error in sending invite', 'error');
        } else {
          toaster('Invite sent successfully');
        }

        triggerEvent(succeededApiCalls);
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
  };

  const closeMoreInfoBanner = () => {
    setShowMoreInfo(MoreInfoStatus.DEFAULT);
  };

  const closeEditGroupPopupHandler = async () => {
    setIsModalVisible(false);
    setSelectedRow(null);
    await fetchGroupListing();
  };

  const licenseCountHandler = async () => {
    const licenseCountResponse = await licenseCount();
    if (licenseCountResponse) {
      setLicenseCountObject(licenseCountResponse);
    }
  };

  useEffect(async () => {
    if (userData?.sub) {
      setSignedInUserDetails(userData);
      await licenseCountHandler();
      fetchGroupListing();
    }
  }, [userData, companyInfo]);

  return (
    <div className={groupTabStyles.groupsTabWrapper}>
      {showMoreInfo === MoreInfoStatus.SECONDARY
      && (
      <MoreInfo
        mainContent={false}
        text="<p>Create, manage and remove a group</p>"
        hideBanner={closeMoreInfoBanner}
      />
      )}
      <p className={invitesTabStyles.title}>Groups Directory</p>
      <div className={groupTabStyles.searchDropdownWrapper}>
        {signedInUserDetails?.role === Role.ADMIN && (<></>) }
      </div>
      <div className={groupTabStyles.tableWrapper}>
        {(signedInUserDetails?.role === Role.ADMIN
        || signedInUserDetails?.role === Role.OWNER) && (
        <button
          id="admin-accounts-groups-create-group"
          className={groupTabStyles.createGroup}
          type="button"
          onClick={() => showModal('Create a group')}
        >
          + Create Group
        </button>
        )}
        <TableComponent
          globalSearchPlaceholder="Search by group name"
          displayColumns={columns}
          allData={tableData}
          tableLoader={isLoading}
          headers={prepareHeaderForCSV()}
          fileName="Group Report.csv"
          domIDs={{
            Download: 'admin-accounts-groups-download',
          }}
        />
      </div>
      {popupTitle === 'Edit group'
        ? (
          <EditGroup
            isModalVisible={isModalVisible}
            onCancel={closeEditGroupPopupHandler}
            onOk={editGroupHandler}
            title={popupTitle}
            selectedRow={selectedRow}
            identityProviderClient={identityProviderClient}
            domID="admin-accounts-groups-edit-group-popup-save"
          />
        ) : (
          <CreateGroup
            isModalVisible={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
              setSelectedRow(null);
            }}
            onOk={createGroupHandler}
            title={popupTitle}
            selectedRow={selectedRow}
          />
        )}
      <AddUser
        isModalVisible={isSendInviteModalVisible}
        onOk={sendInvite}
        onCancel={() => { setSendInviteModalVisible(false); }}
        selectedRow={selectedRow}
        identityProviderClient={identityProviderClient}
        passUploadedArrayFromFile={passUploadedArrayFromFile}
        setIsImportStatusModalVisible={setIsImportStatusModalVisible}
        companyInfo={companyInfo}
        licenseCountObject={licenseCountObject}
        domIDs={{
          GSuite: 'admin-accounts-groups-invite-popup-gsuite',
          SendInvite: 'admin-accounts-groups-invite-popup-send-invite',
          UploadCSV: 'admin-accounts-groups-invite-popup-upload-csv',
          Invite: 'admin-accounts-groups-google-contacts-popup-invite',
          DownloadSampleCSV: 'admin-accounts-groups-invite-popup-download-sample-csv',
        }}
      />
      <ImportedUserProgress
        isImportStatusModalVisible={isImportStatusModalVisible}
        onCancel={() => setIsImportStatusModalVisible(false)}
        identityProviderClient={identityProviderClient}
        fileDataArray={fileDataArray}
        jsonToCSV={jsonToCSV}
        selectedRow={selectedRow}
        domID="admin-accounts-groups-csv-upload-progress-popup-download-failed-records"
      />
      <BuyMoreLicense
        profileInfo={profileInfo}
        isModalVisible={licenseExceeded}
        onCancel={() => setLicenseExceeded(false)}
      />
      {showAssignCoursePopUp && (
        <AssignCoursePopUp
          triggerAssignCoursePopUp={setShowAssignCoursePopUp}
        />
      )}
      <ConfirmPopup
        onOk={() => onConfirmDelete(selectedRow)}
        onCancel={() => {
          setShowDeleteModal(false);
          setSelectedRow(null);
        }}
        modalObject={{
          isVisible: showDeleteModal,
          title: 'Delete Group',
          description: 'Are you sure you want to delete this group?',
          buttonText: 'Delete group',
          selectedUser: selectedRow,
        }}
        isConfirmPopupLoading={isConfirmPopupLoading}
        domIDs={{
          OK: 'admin-accounts-groups-delete-group-popup-delete-group',
          Cancel: 'admin-accounts-groups-delete-group-popup-cancel',
        }}
      />
    </div>
  );
}
GroupsTabView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GroupsTabView;
