// extracted by mini-css-extract-plugin
export var groupsTabWrapper = "GroupsTab-module--groupsTabWrapper--Z21J2";
export var plusIcon = "GroupsTab-module--plusIcon--3i1At";
export var searchDropdownWrapper = "GroupsTab-module--searchDropdownWrapper--2Qllt";
export var tableWrapper = "GroupsTab-module--tableWrapper--bYiMR";
export var createGroup = "GroupsTab-module--createGroup--2bwEx";
export var addProfile = "GroupsTab-module--addProfile--23cUU";
export var table = "GroupsTab-module--table--1ApGi";
export var moreOptions = "GroupsTab-module--moreOptions--3R_jX";
export var member = "GroupsTab-module--member--27yZr";
export var dateCreated = "GroupsTab-module--dateCreated--1Uz2E";