import React, { useContext, useEffect, useState } from 'react';
import {
  Modal, Select, Input, Form, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as createGroupStyles from '../../../../styles/admin/CreateGroup.module.scss';
import AdminContext from '../../../../context/Admin/AdminContext';
import { listAllUsers } from '../Services/GroupsTabService';
import { toaster } from '../../../../services/utils';

const CreateGroup = ({
  isModalVisible, onOk, onCancel, title, selectedRow, showAddUsersField = true,
}) => {
  const [form] = Form.useForm();
  const [emailList, setEmailList] = useState([]);
  const [usersInGroup, setUsersInGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { enterpriseUsersList } = useContext(AdminContext);
  const submitForm = async (values) => {
    if (!form.getFieldValue('groupName')) {
      toaster('Group name cannot be empty', 'error');
      return;
    }
    setIsLoading(true);
    await onOk(values);
    form.setFieldsValue({
      groupName: '',
      users: [],
    });
    setIsLoading(false);
  };

  const handleChange = (_, values) => {
    const availableUsers = values.map((data) => ({
      email: data.children,
      sub: data.value,
    }));
    setUsersInGroup(availableUsers);
  };

  useEffect(async () => {
    const emails = listAllUsers(enterpriseUsersList);
    setEmailList(emails);
  }, [enterpriseUsersList]);

  useEffect(() => {
    form.setFieldsValue({
      groupName: '',
      users: [],
    });
  }, [title, selectedRow?.groupName]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      width="85%"
      className="wrapper"
      footer={null}
    >
      <Form
        form={form}
        labelCol={{
          offset: 3,
          span: 19,
        }}
        wrapperCol={{
          offset: 3,
          span: 19,
        }}
        onFinish={submitForm}
      >
        <div className={createGroupStyles.CreateGroupWrapper}>
          <div className={commonPopupStyles.titleWrapper}>
            <p>{title}</p>
          </div>
          <Form.Item
            label="Group Name"
            name="groupName"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Enter group name"
            />
          </Form.Item>
          {showAddUsersField && (
          <Form.Item
            label="Add Learners/Managers"
            name="users"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Search by email"
              onChange={handleChange}
              optionFilterProp="label"
              filterOption={
                (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionLabelProp="label"
              filterSort={
                (optionA, optionB) => optionA.label.toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              options={emailList}
            />
          </Form.Item>
          )}
          {
            title === 'Edit group' && usersInGroup?.length !== 0
              ? (
                <div className={createGroupStyles.selectWrapper}>
                  <p>SELECTED USERS</p>
                  <div>
                    <span className={createGroupStyles.name}>Name</span>
                    <span className={createGroupStyles.role}>Roles</span>
                  </div>
                  {usersInGroup?.length !== 0
                && usersInGroup.map((user) => (
                  <div className={createGroupStyles.dataWrapper}>
                    <span className={createGroupStyles.member}>{user.email}</span>
                    <span className={createGroupStyles.designation}>{user.role}</span>
                    <span
                      className={createGroupStyles.cancel}
                    >
                      <img src="/images/admin/close-icon.svg" alt="close" />
                    </span>
                  </div>
                ))}
                </div>
              ) : <div className={createGroupStyles.selectWrapper}><p>No users</p></div>
          }
          <p className={createGroupStyles.addmember}>
            You can always add more members from Groups Directory later
          </p>
          <div className={commonPopupStyles.buttonWrapper}>
            <button
              disabled={isLoading}
              type="button"
              className={commonPopupStyles.confirmButton}
              onClick={form.submit}
            >
              Confirm
            </button>
            {
            isLoading
            && <Spin className={commonPopupStyles.spinner} />
          }
          </div>
        </div>
      </Form>
    </Modal>
  );
};
CreateGroup.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedRow: PropTypes.objectOf(PropTypes.any),
  showAddUsersField: PropTypes.bool.isRequired,
};
CreateGroup.defaultProps = {
  selectedRow: null,
};

export default CreateGroup;
