// extracted by mini-css-extract-plugin
export var buyMoreLicenseWrapper = "BuyMoreLicense-module--buyMoreLicenseWrapper--3GeEb";
export var headerSection = "BuyMoreLicense-module--headerSection--208r9";
export var noLicenseAvailableContainer = "BuyMoreLicense-module--noLicenseAvailableContainer--2Hmub";
export var zero = "BuyMoreLicense-module--zero--2tCcE";
export var licensesAvailable = "BuyMoreLicense-module--licensesAvailable--3vBni";
export var licensesInUse = "BuyMoreLicense-module--licensesInUse--3u4TS";
export var title = "BuyMoreLicense-module--title--1sHHt";
export var description = "BuyMoreLicense-module--description--3mAHl";
export var contactUsCta = "BuyMoreLicense-module--contactUsCta--3gYFV";
export var chatBoxIcon = "BuyMoreLicense-module--chatBoxIcon--7EMdL";