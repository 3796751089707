import React, { useEffect, useState, useContext } from 'react';
import {
  Modal, Select, Input, Form, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { Role } from '../../../../models/index';
import AdminContext from '../../../../context/Admin/AdminContext';
import { listUsersNotInGroup, getUsersInGroup } from '../Services/EditGroupService';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as CreateGroupStyles from '../../../../styles/admin/CreateGroup.module.scss';
import {
  getGroupMembersByGroupId, adminUpdateUser, updateGroupMember,
} from '../../../GraphQL/Services';
import {
  removeUserFromGroup as removeUserFromCognito,
} from '../../../Identity/Services';
import { toaster } from '../../../../services/utils';

const EditGroup = ({
  isModalVisible, onOk, onCancel, title, selectedRow, identityProviderClient, domID,
}) => {
  const {
    userData, enterpriseUsersList, mapOfGroupsList,
  } = useContext(AdminContext);
  const [signedInUserDetails, setSignedInUserDetails] = useState(null);
  const [form] = Form.useForm();
  const [emailList, setEmailList] = useState([]);
  const [usersInGroup, setUsersInGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataIsLoading, setDataIsLoading] = useState(false);

  const submitForm = async (values) => {
    setIsLoading(true);
    await onOk(values, selectedRow, enterpriseUsersList);
    setIsLoading(false);
    onCancel();
    form.setFieldsValue({ groupName: '', users: [] });
    setUsersInGroup([]);
  };

  /**
   * remove user from cognito group
   *
   * @param {*} email
   * @param {*} groupName
   */
  const removeUserFromCognitoGroup = async (email, groupName) => {
    const groupObject = {
      groupName,
      user: email,
    };
    await removeUserFromCognito(identityProviderClient, groupObject);
  };

  // /**
  //    * Add user to cognito group
  //    *
  //    * @param {*} email
  //    * @param {*} groupName
  //    */
  // const addUserToCognitoGroup = async (email, groupName) => {
  //   const groupObject = {
  //     groupName,
  //     user: email,
  //   };
  //   await addUserToGroup(identityProviderClient, groupObject);
  // };

  const checkIfManager = async (user) => {
    let roles = [];
    const groupsUserBelongsTo = [];
    let isManager = false;

    const userDetails = enterpriseUsersList.get(user?.memberId);
    roles = [...userDetails.roles];

    // Fetching groups other than the current one
    mapOfGroupsList?.forEach((group) => {
      const memberGroup = group?.memberInfo.filter(
        (member) => !member?.deletedAt
        && member?.memberID === user?.memberId
        && member?.id !== user?.id,
      );

      if (memberGroup?.length) {
        groupsUserBelongsTo.push(memberGroup);
      }
    });

    // Checking if the user is a manager in any other group
    if (groupsUserBelongsTo?.length) {
      for (let i = 0; i < groupsUserBelongsTo.length; i += 1) {
        if (groupsUserBelongsTo[i].isManager) {
          isManager = true;
          break;
        }
      }
    }

    // If user is not a manager in any other group then remove him from the manager group
    if (!isManager && roles.includes('MANAGER')) {
      removeUserFromCognitoGroup(user?.email, 'manager');
      const index = roles.indexOf('MANAGER');
      roles.splice(index, 1);
    }

    // eslint-disable-next-line no-underscore-dangle
    await adminUpdateUser(userDetails?.id, [...roles], userDetails?._version);
  };

  /**
   * remove from selected users
   *
   * @param {*} userToRemove
   * @param {*} gId
   */
  const removeUser = async (userToRemove) => {
    const selectedUsers = usersInGroup.filter((user) => user.email !== userToRemove?.email);
    setUsersInGroup([...selectedUsers]);
    const formUsers = form.getFieldValue('users');
    if (formUsers) {
      formUsers?.splice(userToRemove?.email, 1);
      form.setFieldsValue({
        users: [...formUsers],
      });
    }
    try {
      const removedUserResponse = await updateGroupMember(
        userToRemove?.id,
        userToRemove?.version,
        false,
        false,
        true,
      );
      if (removedUserResponse) {
        toaster('User removed from group', 'success', 2);
        checkIfManager(userToRemove);
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
      setUsersInGroup([...usersInGroup]);
    }
  };

  useEffect(async () => {
    if (isModalVisible) {
      setDataIsLoading(true);
      const usersInCurrentGroup = await getGroupMembersByGroupId(selectedRow?.id);

      const users = await getUsersInGroup(usersInCurrentGroup, enterpriseUsersList);
      setUsersInGroup(users);

      const emails = await listUsersNotInGroup(users, enterpriseUsersList);
      setEmailList(emails);

      form.setFieldsValue({ groupName: selectedRow?.groupName });
      setDataIsLoading(false);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (userData?.sub) {
      setSignedInUserDetails(userData);
    }
  }, [userData]);

  useEffect(async () => {
    const emails = await listUsersNotInGroup(usersInGroup, enterpriseUsersList);
    setEmailList(emails);
  }, [usersInGroup, enterpriseUsersList]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={() => {
        form.setFieldsValue({ groupName: '', users: [] });
        setUsersInGroup([]);
        onCancel();
      }}
      width="85%"
      className="wrapper"
      footer={null}
    >
      <Form
        form={form}
        labelCol={{
          offset: 3,
          span: 19,
        }}
        wrapperCol={{
          offset: 3,
          span: 19,
        }}
        onFinish={submitForm}
      >
        <div className={CreateGroupStyles.CreateGroupWrapper}>
          <div className={commonPopupStyles.titleWrapper}>
            <p>{title}</p>
          </div>
          <Form.Item
            label="Group Name"
            name="groupName"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="Enter group name"
            />
          </Form.Item>
          <Form.Item
            label="Add Learners/Managers"
            name="users"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Search by email"
              optionFilterProp="label"
              filterOption={
                (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionLabelProp="label"
              filterSort={
                (optionA, optionB) => optionA.label.toLowerCase()
                  .localeCompare(optionB.label.toLowerCase())
              }
              options={emailList}
            />
          </Form.Item>
          {
            dataIsLoading && <Spin />
          }
          {
            !dataIsLoading
            && usersInGroup?.length !== 0
              ? (
                <div className={CreateGroupStyles.selectWrapper}>
                  <p style={{ marginTop: '20px' }}>SELECTED USERS</p>
                  <div>
                    <span className={CreateGroupStyles.name}>Name</span>
                    <span className={CreateGroupStyles.role}>Roles</span>
                  </div>
                  { usersInGroup.map((user) => (
                    <div key={user.id} className={CreateGroupStyles.dataWrapper}>
                      <span className={CreateGroupStyles.member}>{user.email}</span>
                      <span className={CreateGroupStyles.designation}>
                        {user.role}
                      </span>
                      <span
                        className={CreateGroupStyles.cancel}
                      >
                        {
                      (
                        user?.id !== signedInUserDetails?.id
                        && (signedInUserDetails?.role === Role.ADMIN
                        || (user?.role !== 'Manager' && signedInUserDetails?.role === Role.MANAGER)
                        || signedInUserDetails?.role === Role.OWNER)
                      )
                      && (
                      <button type="button" onClick={() => removeUser(user, selectedRow?.id)}>
                        <img src="/images/admin/close-icon.svg" alt="close" />
                      </button>
                      )
                    }
                      </span>
                    </div>
                  ))}
                </div>
              ) : <div className={CreateGroupStyles.selectWrapper}><p>No users</p></div>
          }
          <p className={CreateGroupStyles.addmember}>
            You can always add more members from Learner Directory later
          </p>
          <div className={commonPopupStyles.buttonWrapper}>
            <button
              id={domID}
              type="button"
              className={commonPopupStyles.confirmButton}
              onClick={form.submit}
            >
              Save
            </button>
            {
            isLoading
            && <Spin className={commonPopupStyles.spinner} />
          }
          </div>
        </div>
      </Form>
    </Modal>
  );
};
EditGroup.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedRow: PropTypes.objectOf(PropTypes.any).isRequired,
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
  domID: PropTypes.string.isRequired,
};

export default EditGroup;
